<template>
  <div>
    <SubNav :title="title" />
    <div class="container-fluid pb-1">
      <div class="mb-sm-4 mx-3 my-4 row">
        <div class="col-lg-12">
          <div class="border">
            <div class="row p-4">
              <div class="col-lg-8">
                <b-form>
                  <div class="form-row">
                    <div class="col-md-6 mb-3">
                      <label for="firstName">First name</label>
                      <input
                        id="firstName"
                        v-model="user.name.first"
                        type="text"
                        class="form-control"
                        disabled
                      />
                    </div>
                    <div class="col-md-6 mb-3">
                      <label for="lastName">Last name</label>
                      <input
                        id="lastName"
                        v-model="user.name.last"
                        type="text"
                        class="form-control"
                        disabled
                      />
                    </div>
                  </div>

                  <b-form-group class="pt-2 pb-2" label="Email">
                    <b-form-input v-model="user.email" disabled></b-form-input>
                  </b-form-group>

                  <b-form-group v-slot="{ ariaDescribedby }" label="Roles" class="user-roles">
                    <b-form-checkbox-group
                      :options="userRoles"
                      disabled
                      :aria-describedby="ariaDescribedby"
                      stacked
                    ></b-form-checkbox-group>
                  </b-form-group>
                </b-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SubNav from "../../components/SubNav.vue";
import { mapGetters } from "vuex";

export default {
  name: "Profile",
  components: { SubNav },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["user"]),
    userRoles() {
      return this.user.roles.map((role) => {
        return {
          value: role,
          text: role,
        };
      });
    },
    title() {
      return this.user.username;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.user-roles {
  max-height: 270px;
  overflow-y: auto;
  scroll-behavior: smooth;
}
</style>
